const menuItems = [
  {
    path: "accueil",
    label: "Accueil",
  },
  {
    path: "services",
    label: "Services",
  },
];

export default menuItems;
